import { useState, useEffect, useCallback, useRef } from 'react';
import { action } from './decorators';
import { useResizeDetector as useResizeD } from 'react-resize-detector';
import router from 'services/router';
import { refsHaveFocus, findFirstFocusableChild } from 'utils/dom';

export { makeStyles, useTheme } from '@material-ui/core/styles';

export function useAction(callback, deps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(action(callback), deps);
}

export function useEffectAction(callback, deps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(action(callback), deps);
}

export function useDisposable(compute, deps, compareFunc) {
  const previousDeps = useRef(deps);
  const disposableRef = useRef(undefined);
  useEffect(() => () => dispose(disposableRef.current), []);

  if (disposableRef.current === undefined) {
    disposableRef.current = createDisposable(compute);
  }

  if (deps != null
    && (!compareFunc || !disposableRef.current || !compareFunc(disposableRef.current))
    && !compareArrayDeps(deps, previousDeps.current)
  ) {
    previousDeps.current = deps;
    dispose(disposableRef.current);
    disposableRef.current = createDisposable(compute);
  }

  return disposableRef.current;
}

function dispose(disposable) {
  if (!disposable) { return; }

  if (typeof disposable === 'function') {
    disposable();
  } else {
    disposable.dispose();
  }
}

function createDisposable(compute) {
  const result = compute() || null;
  // Debug check to see if we are actually passing disposables
  if (IS_DEBUG) {
    if (result && typeof result !== 'function' && typeof result.dispose !== 'function') {
      throw new Error('Disposable is not actually disposable');
    }
  }
  return result;
}

// Only execute a function on mount, useful if you have deps to not have error
export function useMount(func) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(func, []);
}

export function useResizeDetector(opts) {
  opts = Object.assign({ refreshMode: 'debounce', refreshRate: 10 }, opts);
  return useResizeD(opts);
}

export function useSingleAndDoubleClick(actionSimpleClick, actionDoubleClick, delay = 200) {
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) { clearTimeout(timerRef.current); timerRef.current = null; }
    };
  }, []);

  return useCallback(e => {
    if (timerRef.current) { clearTimeout(timerRef.current); timerRef.current = null; }
    switch (e.detail) {
      case 1:
        timerRef.current = setTimeout(() => {
          actionSimpleClick();
          timerRef.current = null;
        }, delay);
        break;
      case 2:
        actionDoubleClick();
        break;
    }
  }, [ actionSimpleClick, actionDoubleClick, delay ]);
}

// We only run the relative navigation once
let hasNavigated = false;
export function useCheckRelativeNavigation(offset) {
  // If we have a 'rel' hash in our url, try to go to that element
  useEffect(() => {
    if (router.hashParts?.rel && !hasNavigated) {
      const element = document.getElementById(router.hashParts.rel);
      if (element) {
        hasNavigated = true;
        const position = element.getBoundingClientRect();
        // scrolls to offset px above element
        window.scrollTo(position.left, position.top + window.scrollY - (offset || 200));
      }
    }
  });
}

export function useCombineRefs(...refs) {
  return useCallback(combineRefs(...refs), refs); // eslint-disable-line react-hooks/exhaustive-deps
}

export function combineRefs(...refs) {
  if (!refs.some(r => r)) { return null; }

  return r => {
    for (const rf of refs) {
      if (!rf) { continue; }

      if (typeof rf === 'function') {
        rf(r);
      } else {
        rf.current = r;
      }
    }
  };
}

export function useAutoFocus(ref, { enabled, deps, timeout } = {}) {
  useEffect(() => {
    if (!ref?.current || (enabled && !enabled())) { return; }

    const t = setTimeout(() => findFirstFocusableChild(ref.current)?.focus(), timeout == null ? 30 : timeout);
    return () => clearTimeout(t);
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useFocused(enabled, ...refs) {
  const [ focused, setFocused ] = useState(() => refsHaveFocus(refs));

  const manualSetFocus = useCallback(focus => {
    // Blur the active element if one of our elements is currently focused and we manually turn this off
    if (!focus && refsHaveFocus(refs)) {
      document.activeElement.blur();
    }
    setFocused(focus);
  }, [ setFocused, ...refs ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!enabled) { return; }

    let timeout = null;
    const onFocus = e => {
      if (timeout) { clearTimeout(timeout); timeout = null; }
      setFocused(refsHaveFocus(refs));
    };

    const onBlur = e => {
      if (timeout) { clearTimeout(timeout); timeout = null; }
      timeout = setTimeout(() => {
        if (!refsHaveFocus(refs)) { setFocused(false); }
      }, 100);
    };

    document.addEventListener('focusin', onFocus, { passive: true });
    document.addEventListener('focusout', onBlur, { passive: true });
    setFocused(refsHaveFocus(refs));

    return () => {
      if (timeout) { clearTimeout(timeout); timeout = null; }
      document.removeEventListener('focusin', onFocus, { passive: true });
      document.removeEventListener('focusout', onBlur, { passive: true });
    };
  }, [ enabled, ...refs ]); // eslint-disable-line react-hooks/exhaustive-deps

  return [ focused || refsHaveFocus(refs), manualSetFocus ];
}

export function collectRefs(refToTrack, number) {
  const refs = Array.apply(null, Array(number)).map(() => useRef(null));

  const onRef = ref => r => {
    ref.current = r;

    if (!refToTrack) { return; }

    let combined = [];
    refs.forEach(r => (combined = combined.concat(r)));
    if (typeof refToTrack === 'function') {
      refToTrack(combined);
    } else {
      refToTrack.current = combined;
    }
  };

  return refs.map(onRef);
}

const compareArrayDeps = (deps, previousDeps) => {
  return Array.isArray(deps)
    && Array.isArray(previousDeps)
    && deps.length === previousDeps.length
    && deps.every((dep, index) => {
      const other = previousDeps[index];
      if (Array.isArray(dep)) {
        return compareArrayDeps(dep, other);
      } else {
        return dep === other;
      }
    });
};